/*
 * ****************************************************************************
 * Copyright (C) 2022-2022 - All rights reserved.
 * Project: firestore-ui
 * Created On: March 10th, 2022
 * ****************************************************************************
 */

// eslint-disable-next-line @typescript-eslint/no-var-requires
const error = require("antlr4/error");

module.exports = error;
