// Generated from /home/rogerk/projects/rogerk/firestore-ui/antlr/grammar/SqlLexer.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');



var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002g\u036b\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004",
    "\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t",
    "\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004",
    "\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010",
    "\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013",
    "\u0004\u0014\t\u0014\u0004\u0015\t\u0015\u0004\u0016\t\u0016\u0004\u0017",
    "\t\u0017\u0004\u0018\t\u0018\u0004\u0019\t\u0019\u0004\u001a\t\u001a",
    "\u0004\u001b\t\u001b\u0004\u001c\t\u001c\u0004\u001d\t\u001d\u0004\u001e",
    "\t\u001e\u0004\u001f\t\u001f\u0004 \t \u0004!\t!\u0004\"\t\"\u0004#",
    "\t#\u0004$\t$\u0004%\t%\u0004&\t&\u0004\'\t\'\u0004(\t(\u0004)\t)\u0004",
    "*\t*\u0004+\t+\u0004,\t,\u0004-\t-\u0004.\t.\u0004/\t/\u00040\t0\u0004",
    "1\t1\u00042\t2\u00043\t3\u00044\t4\u00045\t5\u00046\t6\u00047\t7\u0004",
    "8\t8\u00049\t9\u0004:\t:\u0004;\t;\u0004<\t<\u0004=\t=\u0004>\t>\u0004",
    "?\t?\u0004@\t@\u0004A\tA\u0004B\tB\u0004C\tC\u0004D\tD\u0004E\tE\u0004",
    "F\tF\u0004G\tG\u0004H\tH\u0004I\tI\u0004J\tJ\u0004K\tK\u0004L\tL\u0004",
    "M\tM\u0004N\tN\u0004O\tO\u0004P\tP\u0004Q\tQ\u0004R\tR\u0004S\tS\u0004",
    "T\tT\u0004U\tU\u0004V\tV\u0004W\tW\u0004X\tX\u0004Y\tY\u0004Z\tZ\u0004",
    "[\t[\u0004\\\t\\\u0004]\t]\u0004^\t^\u0004_\t_\u0004`\t`\u0004a\ta\u0004",
    "b\tb\u0004c\tc\u0004d\td\u0004e\te\u0004f\tf\u0004g\tg\u0004h\th\u0004",
    "i\ti\u0004j\tj\u0004k\tk\u0004l\tl\u0004m\tm\u0004n\tn\u0004o\to\u0003",
    "\u0002\u0006\u0002\u00e1\n\u0002\r\u0002\u000e\u0002\u00e2\u0003\u0002",
    "\u0003\u0002\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0006\u0003\u00ec\n\u0003\r\u0003\u000e\u0003\u00ed\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0004\u0003\u0004\u0003",
    "\u0004\u0003\u0004\u0007\u0004\u00f9\n\u0004\f\u0004\u000e\u0004\u00fc",
    "\u000b\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004",
    "\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005",
    "\u0005\u0005\u0109\n\u0005\u0003\u0005\u0007\u0005\u010c\n\u0005\f\u0005",
    "\u000e\u0005\u010f\u000b\u0005\u0003\u0005\u0005\u0005\u0112\n\u0005",
    "\u0003\u0005\u0003\u0005\u0005\u0005\u0116\n\u0005\u0003\u0005\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0005\u0005\u011c\n\u0005\u0003\u0005",
    "\u0003\u0005\u0005\u0005\u0120\n\u0005\u0005\u0005\u0122\n\u0005\u0003",
    "\u0005\u0003\u0005\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0007\u0003\u0007\u0003\u0007\u0003\b\u0003\b\u0003\b\u0003\b\u0003",
    "\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\n\u0003",
    "\n\u0003\n\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b",
    "\u0003\u000b\u0003\u000b\u0003\u000b\u0003\f\u0003\f\u0003\f\u0003\f",
    "\u0003\f\u0003\f\u0003\f\u0003\r\u0003\r\u0003\r\u0003\r\u0003\r\u0003",
    "\u000e\u0003\u000e\u0003\u000e\u0003\u000e\u0003\u000e\u0003\u000e\u0003",
    "\u000f\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u0010\u0003",
    "\u0010\u0003\u0010\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003",
    "\u0011\u0003\u0011\u0003\u0011\u0003\u0012\u0003\u0012\u0003\u0012\u0003",
    "\u0012\u0003\u0012\u0003\u0013\u0003\u0013\u0003\u0013\u0003\u0014\u0003",
    "\u0014\u0003\u0014\u0003\u0014\u0003\u0015\u0003\u0015\u0003\u0015\u0003",
    "\u0015\u0003\u0015\u0003\u0016\u0003\u0016\u0003\u0016\u0003\u0016\u0003",
    "\u0016\u0003\u0016\u0003\u0017\u0003\u0017\u0003\u0017\u0003\u0017\u0003",
    "\u0018\u0003\u0018\u0003\u0018\u0003\u0018\u0003\u0018\u0003\u0019\u0003",
    "\u0019\u0003\u0019\u0003\u0019\u0003\u0019\u0003\u0019\u0003\u0019\u0003",
    "\u001a\u0003\u001a\u0003\u001a\u0003\u001b\u0003\u001b\u0003\u001b\u0003",
    "\u001b\u0003\u001b\u0003\u001b\u0003\u001c\u0003\u001c\u0003\u001c\u0003",
    "\u001c\u0003\u001c\u0003\u001c\u0003\u001c\u0003\u001d\u0003\u001d\u0003",
    "\u001d\u0003\u001d\u0003\u001d\u0003\u001d\u0003\u001e\u0003\u001e\u0003",
    "\u001e\u0003\u001e\u0003\u001e\u0003\u001e\u0003\u001e\u0003\u001f\u0003",
    "\u001f\u0003\u001f\u0003\u001f\u0003 \u0003 \u0003 \u0003 \u0003 \u0003",
    "!\u0003!\u0003!\u0003!\u0003!\u0003!\u0003!\u0003\"\u0003\"\u0003\"",
    "\u0003\"\u0003\"\u0003\"\u0003#\u0003#\u0003#\u0003#\u0003#\u0003#\u0003",
    "#\u0003$\u0003$\u0003$\u0003$\u0003$\u0003$\u0003%\u0003%\u0003%\u0003",
    "%\u0003%\u0003&\u0003&\u0003&\u0003&\u0003\'\u0003\'\u0003\'\u0003\'",
    "\u0003\'\u0003\'\u0003\'\u0003\'\u0003(\u0003(\u0003(\u0003(\u0003(",
    "\u0003)\u0003)\u0003)\u0003)\u0003)\u0003)\u0003)\u0003*\u0003*\u0003",
    "*\u0003*\u0003*\u0003*\u0003+\u0003+\u0003+\u0003+\u0003+\u0003+\u0003",
    "+\u0003+\u0003,\u0003,\u0003,\u0003,\u0003,\u0003,\u0003,\u0003,\u0003",
    "-\u0003-\u0003-\u0003-\u0003-\u0003.\u0003.\u0003.\u0003.\u0003.\u0003",
    ".\u0003.\u0003.\u0003.\u0003.\u0003/\u0003/\u0003/\u0003/\u0003/\u0003",
    "/\u0003/\u0003/\u0003/\u00030\u00030\u00030\u00030\u00030\u00031\u0003",
    "1\u00031\u00031\u00031\u00031\u00032\u00032\u00032\u00032\u00032\u0003",
    "2\u00032\u00032\u00033\u00033\u00033\u00033\u00033\u00033\u00033\u0003",
    "3\u00033\u00034\u00034\u00034\u00034\u00034\u00035\u00035\u00035\u0003",
    "5\u00035\u00036\u00036\u00036\u00036\u00036\u00036\u00036\u00036\u0003",
    "7\u00037\u00037\u00037\u00037\u00038\u00038\u00038\u00038\u00038\u0003",
    "8\u00038\u00039\u00039\u00039\u00039\u0003:\u0003:\u0003:\u0003:\u0003",
    ":\u0003:\u0003:\u0003:\u0003:\u0003:\u0003;\u0003;\u0003<\u0003<\u0003",
    "=\u0003=\u0003>\u0003>\u0003?\u0003?\u0003?\u0003@\u0003@\u0003A\u0003",
    "A\u0003B\u0003B\u0003C\u0003C\u0003D\u0003D\u0003E\u0003E\u0003F\u0003",
    "F\u0003G\u0003G\u0003H\u0003H\u0003I\u0003I\u0003J\u0003J\u0003K\u0003",
    "K\u0003L\u0003L\u0003M\u0003M\u0003N\u0003N\u0003O\u0003O\u0003P\u0003",
    "P\u0003Q\u0003Q\u0003R\u0003R\u0003S\u0003S\u0003S\u0005S\u0293\nS\u0003",
    "T\u0006T\u0296\nT\rT\u000eT\u0297\u0003T\u0003T\u0003U\u0003U\u0003",
    "U\u0003V\u0003V\u0003W\u0006W\u02a2\nW\rW\u000eW\u02a3\u0003X\u0003",
    "X\u0003X\u0003X\u0003X\u0006X\u02ab\nX\rX\u000eX\u02ac\u0003X\u0003",
    "X\u0003X\u0003X\u0003X\u0003X\u0006X\u02b5\nX\rX\u000eX\u02b6\u0005",
    "X\u02b9\nX\u0003Y\u0006Y\u02bc\nY\rY\u000eY\u02bd\u0005Y\u02c0\nY\u0003",
    "Y\u0003Y\u0006Y\u02c4\nY\rY\u000eY\u02c5\u0003Y\u0006Y\u02c9\nY\rY\u000e",
    "Y\u02ca\u0003Y\u0003Y\u0003Y\u0003Y\u0006Y\u02d1\nY\rY\u000eY\u02d2",
    "\u0005Y\u02d5\nY\u0003Y\u0003Y\u0006Y\u02d9\nY\rY\u000eY\u02da\u0003",
    "Y\u0003Y\u0003Y\u0006Y\u02e0\nY\rY\u000eY\u02e1\u0003Y\u0003Y\u0005",
    "Y\u02e6\nY\u0003Z\u0003Z\u0003Z\u0003[\u0003[\u0003\\\u0003\\\u0003",
    "]\u0003]\u0003^\u0003^\u0003_\u0003_\u0003`\u0003`\u0003`\u0003a\u0003",
    "a\u0003b\u0003b\u0003c\u0003c\u0003d\u0003d\u0003d\u0003d\u0005d\u0302",
    "\nd\u0003d\u0003d\u0003d\u0003d\u0003d\u0005d\u0309\nd\u0003e\u0003",
    "e\u0006e\u030d\ne\re\u000ee\u030e\u0003e\u0003e\u0003e\u0005e\u0314",
    "\ne\u0003f\u0003f\u0003f\u0006f\u0319\nf\rf\u000ef\u031a\u0003f\u0005",
    "f\u031e\nf\u0003g\u0003g\u0005g\u0322\ng\u0003g\u0006g\u0325\ng\rg\u000e",
    "g\u0326\u0003h\u0006h\u032a\nh\rh\u000eh\u032b\u0003h\u0007h\u032f\n",
    "h\fh\u000eh\u0332\u000bh\u0003i\u0003i\u0003i\u0003i\u0003i\u0003i\u0007",
    "i\u033a\ni\fi\u000ei\u033d\u000bi\u0003i\u0003i\u0003j\u0003j\u0003",
    "j\u0003j\u0003j\u0003j\u0007j\u0347\nj\fj\u000ej\u034a\u000bj\u0003",
    "j\u0003j\u0003k\u0003k\u0003k\u0003k\u0003k\u0003k\u0007k\u0354\nk\f",
    "k\u000ek\u0357\u000bk\u0003k\u0003k\u0003l\u0003l\u0003m\u0003m\u0003",
    "n\u0003n\u0003n\u0006n\u0362\nn\rn\u000en\u0363\u0003n\u0003n\u0003",
    "o\u0003o\u0003o\u0003o\u0005\u00ed\u00fa\u032b\u0002p\u0003\u0003\u0005",
    "\u0004\u0007\u0005\t\u0006\u000b\u0007\r\b\u000f\t\u0011\n\u0013\u000b",
    "\u0015\f\u0017\r\u0019\u000e\u001b\u000f\u001d\u0010\u001f\u0011!\u0012",
    "#\u0013%\u0014\'\u0015)\u0016+\u0017-\u0018/\u00191\u001a3\u001b5\u001c",
    "7\u001d9\u001e;\u001f= ?!A\"C#E$G%I&K\'M(O)Q*S+U,W-Y.[/]0_1a2c3e4g5",
    "i6k7m8o9q:s;u<w=y>{?}@\u007fA\u0081B\u0083C\u0085D\u0087E\u0089F\u008b",
    "G\u008dH\u008fI\u0091J\u0093K\u0095L\u0097M\u0099N\u009bO\u009dP\u009f",
    "Q\u00a1R\u00a3S\u00a5\u0002\u00a7T\u00a9U\u00abV\u00adW\u00afX\u00b1",
    "Y\u00b3Z\u00b5[\u00b7\\\u00b9]\u00bb^\u00bd_\u00bf`\u00c1a\u00c3b\u00c5",
    "c\u00c7d\u00c9e\u00cbf\u00cd\u0002\u00cf\u0002\u00d1\u0002\u00d3\u0002",
    "\u00d5\u0002\u00d7\u0002\u00d9\u0002\u00db\u0002\u00ddg\u0003\u0002",
    "\u000f\u0005\u0002\u000b\f\u000f\u000f\"\"\u0004\u0002\f\f\u000f\u000f",
    "\u0006\u0002IIMMOOVV\u0007\u0002&&002;C\\aa\u0004\u0002--//\u0005\u0002",
    "&&C\\aa\u0006\u0002&&2;C\\aa\u0004\u0002$$^^\u0004\u0002))^^\u0004\u0002",
    "^^bb\u0004\u00022;CH\u0003\u00022;\u0003\u000223\u0002\u0398\u0002\u0003",
    "\u0003\u0002\u0002\u0002\u0002\u0005\u0003\u0002\u0002\u0002\u0002\u0007",
    "\u0003\u0002\u0002\u0002\u0002\t\u0003\u0002\u0002\u0002\u0002\u000b",
    "\u0003\u0002\u0002\u0002\u0002\r\u0003\u0002\u0002\u0002\u0002\u000f",
    "\u0003\u0002\u0002\u0002\u0002\u0011\u0003\u0002\u0002\u0002\u0002\u0013",
    "\u0003\u0002\u0002\u0002\u0002\u0015\u0003\u0002\u0002\u0002\u0002\u0017",
    "\u0003\u0002\u0002\u0002\u0002\u0019\u0003\u0002\u0002\u0002\u0002\u001b",
    "\u0003\u0002\u0002\u0002\u0002\u001d\u0003\u0002\u0002\u0002\u0002\u001f",
    "\u0003\u0002\u0002\u0002\u0002!\u0003\u0002\u0002\u0002\u0002#\u0003",
    "\u0002\u0002\u0002\u0002%\u0003\u0002\u0002\u0002\u0002\'\u0003\u0002",
    "\u0002\u0002\u0002)\u0003\u0002\u0002\u0002\u0002+\u0003\u0002\u0002",
    "\u0002\u0002-\u0003\u0002\u0002\u0002\u0002/\u0003\u0002\u0002\u0002",
    "\u00021\u0003\u0002\u0002\u0002\u00023\u0003\u0002\u0002\u0002\u0002",
    "5\u0003\u0002\u0002\u0002\u00027\u0003\u0002\u0002\u0002\u00029\u0003",
    "\u0002\u0002\u0002\u0002;\u0003\u0002\u0002\u0002\u0002=\u0003\u0002",
    "\u0002\u0002\u0002?\u0003\u0002\u0002\u0002\u0002A\u0003\u0002\u0002",
    "\u0002\u0002C\u0003\u0002\u0002\u0002\u0002E\u0003\u0002\u0002\u0002",
    "\u0002G\u0003\u0002\u0002\u0002\u0002I\u0003\u0002\u0002\u0002\u0002",
    "K\u0003\u0002\u0002\u0002\u0002M\u0003\u0002\u0002\u0002\u0002O\u0003",
    "\u0002\u0002\u0002\u0002Q\u0003\u0002\u0002\u0002\u0002S\u0003\u0002",
    "\u0002\u0002\u0002U\u0003\u0002\u0002\u0002\u0002W\u0003\u0002\u0002",
    "\u0002\u0002Y\u0003\u0002\u0002\u0002\u0002[\u0003\u0002\u0002\u0002",
    "\u0002]\u0003\u0002\u0002\u0002\u0002_\u0003\u0002\u0002\u0002\u0002",
    "a\u0003\u0002\u0002\u0002\u0002c\u0003\u0002\u0002\u0002\u0002e\u0003",
    "\u0002\u0002\u0002\u0002g\u0003\u0002\u0002\u0002\u0002i\u0003\u0002",
    "\u0002\u0002\u0002k\u0003\u0002\u0002\u0002\u0002m\u0003\u0002\u0002",
    "\u0002\u0002o\u0003\u0002\u0002\u0002\u0002q\u0003\u0002\u0002\u0002",
    "\u0002s\u0003\u0002\u0002\u0002\u0002u\u0003\u0002\u0002\u0002\u0002",
    "w\u0003\u0002\u0002\u0002\u0002y\u0003\u0002\u0002\u0002\u0002{\u0003",
    "\u0002\u0002\u0002\u0002}\u0003\u0002\u0002\u0002\u0002\u007f\u0003",
    "\u0002\u0002\u0002\u0002\u0081\u0003\u0002\u0002\u0002\u0002\u0083\u0003",
    "\u0002\u0002\u0002\u0002\u0085\u0003\u0002\u0002\u0002\u0002\u0087\u0003",
    "\u0002\u0002\u0002\u0002\u0089\u0003\u0002\u0002\u0002\u0002\u008b\u0003",
    "\u0002\u0002\u0002\u0002\u008d\u0003\u0002\u0002\u0002\u0002\u008f\u0003",
    "\u0002\u0002\u0002\u0002\u0091\u0003\u0002\u0002\u0002\u0002\u0093\u0003",
    "\u0002\u0002\u0002\u0002\u0095\u0003\u0002\u0002\u0002\u0002\u0097\u0003",
    "\u0002\u0002\u0002\u0002\u0099\u0003\u0002\u0002\u0002\u0002\u009b\u0003",
    "\u0002\u0002\u0002\u0002\u009d\u0003\u0002\u0002\u0002\u0002\u009f\u0003",
    "\u0002\u0002\u0002\u0002\u00a1\u0003\u0002\u0002\u0002\u0002\u00a3\u0003",
    "\u0002\u0002\u0002\u0002\u00a7\u0003\u0002\u0002\u0002\u0002\u00a9\u0003",
    "\u0002\u0002\u0002\u0002\u00ab\u0003\u0002\u0002\u0002\u0002\u00ad\u0003",
    "\u0002\u0002\u0002\u0002\u00af\u0003\u0002\u0002\u0002\u0002\u00b1\u0003",
    "\u0002\u0002\u0002\u0002\u00b3\u0003\u0002\u0002\u0002\u0002\u00b5\u0003",
    "\u0002\u0002\u0002\u0002\u00b7\u0003\u0002\u0002\u0002\u0002\u00b9\u0003",
    "\u0002\u0002\u0002\u0002\u00bb\u0003\u0002\u0002\u0002\u0002\u00bd\u0003",
    "\u0002\u0002\u0002\u0002\u00bf\u0003\u0002\u0002\u0002\u0002\u00c1\u0003",
    "\u0002\u0002\u0002\u0002\u00c3\u0003\u0002\u0002\u0002\u0002\u00c5\u0003",
    "\u0002\u0002\u0002\u0002\u00c7\u0003\u0002\u0002\u0002\u0002\u00c9\u0003",
    "\u0002\u0002\u0002\u0002\u00cb\u0003\u0002\u0002\u0002\u0002\u00dd\u0003",
    "\u0002\u0002\u0002\u0003\u00e0\u0003\u0002\u0002\u0002\u0005\u00e6\u0003",
    "\u0002\u0002\u0002\u0007\u00f4\u0003\u0002\u0002\u0002\t\u0121\u0003",
    "\u0002\u0002\u0002\u000b\u0125\u0003\u0002\u0002\u0002\r\u0129\u0003",
    "\u0002\u0002\u0002\u000f\u012c\u0003\u0002\u0002\u0002\u0011\u0130\u0003",
    "\u0002\u0002\u0002\u0013\u0138\u0003\u0002\u0002\u0002\u0015\u013b\u0003",
    "\u0002\u0002\u0002\u0017\u0143\u0003\u0002\u0002\u0002\u0019\u014a\u0003",
    "\u0002\u0002\u0002\u001b\u014f\u0003\u0002\u0002\u0002\u001d\u0155\u0003",
    "\u0002\u0002\u0002\u001f\u015a\u0003\u0002\u0002\u0002!\u015d\u0003",
    "\u0002\u0002\u0002#\u0164\u0003\u0002\u0002\u0002%\u0169\u0003\u0002",
    "\u0002\u0002\'\u016c\u0003\u0002\u0002\u0002)\u0170\u0003\u0002\u0002",
    "\u0002+\u0175\u0003\u0002\u0002\u0002-\u017b\u0003\u0002\u0002\u0002",
    "/\u017f\u0003\u0002\u0002\u00021\u0184\u0003\u0002\u0002\u00023\u018b",
    "\u0003\u0002\u0002\u00025\u018e\u0003\u0002\u0002\u00027\u0194\u0003",
    "\u0002\u0002\u00029\u019b\u0003\u0002\u0002\u0002;\u01a1\u0003\u0002",
    "\u0002\u0002=\u01a8\u0003\u0002\u0002\u0002?\u01ac\u0003\u0002\u0002",
    "\u0002A\u01b1\u0003\u0002\u0002\u0002C\u01b8\u0003\u0002\u0002\u0002",
    "E\u01be\u0003\u0002\u0002\u0002G\u01c5\u0003\u0002\u0002\u0002I\u01cb",
    "\u0003\u0002\u0002\u0002K\u01d0\u0003\u0002\u0002\u0002M\u01d4\u0003",
    "\u0002\u0002\u0002O\u01dc\u0003\u0002\u0002\u0002Q\u01e1\u0003\u0002",
    "\u0002\u0002S\u01e8\u0003\u0002\u0002\u0002U\u01ee\u0003\u0002\u0002",
    "\u0002W\u01f6\u0003\u0002\u0002\u0002Y\u01fe\u0003\u0002\u0002\u0002",
    "[\u0203\u0003\u0002\u0002\u0002]\u020d\u0003\u0002\u0002\u0002_\u0216",
    "\u0003\u0002\u0002\u0002a\u021b\u0003\u0002\u0002\u0002c\u0221\u0003",
    "\u0002\u0002\u0002e\u0229\u0003\u0002\u0002\u0002g\u0232\u0003\u0002",
    "\u0002\u0002i\u0237\u0003\u0002\u0002\u0002k\u023c\u0003\u0002\u0002",
    "\u0002m\u0244\u0003\u0002\u0002\u0002o\u0249\u0003\u0002\u0002\u0002",
    "q\u0250\u0003\u0002\u0002\u0002s\u0254\u0003\u0002\u0002\u0002u\u025e",
    "\u0003\u0002\u0002\u0002w\u0260\u0003\u0002\u0002\u0002y\u0262\u0003",
    "\u0002\u0002\u0002{\u0264\u0003\u0002\u0002\u0002}\u0266\u0003\u0002",
    "\u0002\u0002\u007f\u0269\u0003\u0002\u0002\u0002\u0081\u026b\u0003\u0002",
    "\u0002\u0002\u0083\u026d\u0003\u0002\u0002\u0002\u0085\u026f\u0003\u0002",
    "\u0002\u0002\u0087\u0271\u0003\u0002\u0002\u0002\u0089\u0273\u0003\u0002",
    "\u0002\u0002\u008b\u0275\u0003\u0002\u0002\u0002\u008d\u0277\u0003\u0002",
    "\u0002\u0002\u008f\u0279\u0003\u0002\u0002\u0002\u0091\u027b\u0003\u0002",
    "\u0002\u0002\u0093\u027d\u0003\u0002\u0002\u0002\u0095\u027f\u0003\u0002",
    "\u0002\u0002\u0097\u0281\u0003\u0002\u0002\u0002\u0099\u0283\u0003\u0002",
    "\u0002\u0002\u009b\u0285\u0003\u0002\u0002\u0002\u009d\u0287\u0003\u0002",
    "\u0002\u0002\u009f\u0289\u0003\u0002\u0002\u0002\u00a1\u028b\u0003\u0002",
    "\u0002\u0002\u00a3\u028d\u0003\u0002\u0002\u0002\u00a5\u0292\u0003\u0002",
    "\u0002\u0002\u00a7\u0295\u0003\u0002\u0002\u0002\u00a9\u029b\u0003\u0002",
    "\u0002\u0002\u00ab\u029e\u0003\u0002\u0002\u0002\u00ad\u02a1\u0003\u0002",
    "\u0002\u0002\u00af\u02b8\u0003\u0002\u0002\u0002\u00b1\u02e5\u0003\u0002",
    "\u0002\u0002\u00b3\u02e7\u0003\u0002\u0002\u0002\u00b5\u02ea\u0003\u0002",
    "\u0002\u0002\u00b7\u02ec\u0003\u0002\u0002\u0002\u00b9\u02ee\u0003\u0002",
    "\u0002\u0002\u00bb\u02f0\u0003\u0002\u0002\u0002\u00bd\u02f2\u0003\u0002",
    "\u0002\u0002\u00bf\u02f4\u0003\u0002\u0002\u0002\u00c1\u02f7\u0003\u0002",
    "\u0002\u0002\u00c3\u02f9\u0003\u0002\u0002\u0002\u00c5\u02fb\u0003\u0002",
    "\u0002\u0002\u00c7\u0301\u0003\u0002\u0002\u0002\u00c9\u030a\u0003\u0002",
    "\u0002\u0002\u00cb\u0315\u0003\u0002\u0002\u0002\u00cd\u031f\u0003\u0002",
    "\u0002\u0002\u00cf\u0329\u0003\u0002\u0002\u0002\u00d1\u0333\u0003\u0002",
    "\u0002\u0002\u00d3\u0340\u0003\u0002\u0002\u0002\u00d5\u034d\u0003\u0002",
    "\u0002\u0002\u00d7\u035a\u0003\u0002\u0002\u0002\u00d9\u035c\u0003\u0002",
    "\u0002\u0002\u00db\u035e\u0003\u0002\u0002\u0002\u00dd\u0367\u0003\u0002",
    "\u0002\u0002\u00df\u00e1\t\u0002\u0002\u0002\u00e0\u00df\u0003\u0002",
    "\u0002\u0002\u00e1\u00e2\u0003\u0002\u0002\u0002\u00e2\u00e0\u0003\u0002",
    "\u0002\u0002\u00e2\u00e3\u0003\u0002\u0002\u0002\u00e3\u00e4\u0003\u0002",
    "\u0002\u0002\u00e4\u00e5\b\u0002\u0002\u0002\u00e5\u0004\u0003\u0002",
    "\u0002\u0002\u00e6\u00e7\u00071\u0002\u0002\u00e7\u00e8\u0007,\u0002",
    "\u0002\u00e8\u00e9\u0007#\u0002\u0002\u00e9\u00eb\u0003\u0002\u0002",
    "\u0002\u00ea\u00ec\u000b\u0002\u0002\u0002\u00eb\u00ea\u0003\u0002\u0002",
    "\u0002\u00ec\u00ed\u0003\u0002\u0002\u0002\u00ed\u00ee\u0003\u0002\u0002",
    "\u0002\u00ed\u00eb\u0003\u0002\u0002\u0002\u00ee\u00ef\u0003\u0002\u0002",
    "\u0002\u00ef\u00f0\u0007,\u0002\u0002\u00f0\u00f1\u00071\u0002\u0002",
    "\u00f1\u00f2\u0003\u0002\u0002\u0002\u00f2\u00f3\b\u0003\u0003\u0002",
    "\u00f3\u0006\u0003\u0002\u0002\u0002\u00f4\u00f5\u00071\u0002\u0002",
    "\u00f5\u00f6\u0007,\u0002\u0002\u00f6\u00fa\u0003\u0002\u0002\u0002",
    "\u00f7\u00f9\u000b\u0002\u0002\u0002\u00f8\u00f7\u0003\u0002\u0002\u0002",
    "\u00f9\u00fc\u0003\u0002\u0002\u0002\u00fa\u00fb\u0003\u0002\u0002\u0002",
    "\u00fa\u00f8\u0003\u0002\u0002\u0002\u00fb\u00fd\u0003\u0002\u0002\u0002",
    "\u00fc\u00fa\u0003\u0002\u0002\u0002\u00fd\u00fe\u0007,\u0002\u0002",
    "\u00fe\u00ff\u00071\u0002\u0002\u00ff\u0100\u0003\u0002\u0002\u0002",
    "\u0100\u0101\b\u0004\u0002\u0002\u0101\b\u0003\u0002\u0002\u0002\u0102",
    "\u0103\u00071\u0002\u0002\u0103\u0109\u00071\u0002\u0002\u0104\u0105",
    "\u0007/\u0002\u0002\u0105\u0106\u0007/\u0002\u0002\u0106\u0109\u0007",
    "\"\u0002\u0002\u0107\u0109\u0007%\u0002\u0002\u0108\u0102\u0003\u0002",
    "\u0002\u0002\u0108\u0104\u0003\u0002\u0002\u0002\u0108\u0107\u0003\u0002",
    "\u0002\u0002\u0109\u010d\u0003\u0002\u0002\u0002\u010a\u010c\n\u0003",
    "\u0002\u0002\u010b\u010a\u0003\u0002\u0002\u0002\u010c\u010f\u0003\u0002",
    "\u0002\u0002\u010d\u010b\u0003\u0002\u0002\u0002\u010d\u010e\u0003\u0002",
    "\u0002\u0002\u010e\u0115\u0003\u0002\u0002\u0002\u010f\u010d\u0003\u0002",
    "\u0002\u0002\u0110\u0112\u0007\u000f\u0002\u0002\u0111\u0110\u0003\u0002",
    "\u0002\u0002\u0111\u0112\u0003\u0002\u0002\u0002\u0112\u0113\u0003\u0002",
    "\u0002\u0002\u0113\u0116\u0007\f\u0002\u0002\u0114\u0116\u0007\u0002",
    "\u0002\u0003\u0115\u0111\u0003\u0002\u0002\u0002\u0115\u0114\u0003\u0002",
    "\u0002\u0002\u0116\u0122\u0003\u0002\u0002\u0002\u0117\u0118\u0007/",
    "\u0002\u0002\u0118\u0119\u0007/\u0002\u0002\u0119\u011f\u0003\u0002",
    "\u0002\u0002\u011a\u011c\u0007\u000f\u0002\u0002\u011b\u011a\u0003\u0002",
    "\u0002\u0002\u011b\u011c\u0003\u0002\u0002\u0002\u011c\u011d\u0003\u0002",
    "\u0002\u0002\u011d\u0120\u0007\f\u0002\u0002\u011e\u0120\u0007\u0002",
    "\u0002\u0003\u011f\u011b\u0003\u0002\u0002\u0002\u011f\u011e\u0003\u0002",
    "\u0002\u0002\u0120\u0122\u0003\u0002\u0002\u0002\u0121\u0108\u0003\u0002",
    "\u0002\u0002\u0121\u0117\u0003\u0002\u0002\u0002\u0122\u0123\u0003\u0002",
    "\u0002\u0002\u0123\u0124\b\u0005\u0002\u0002\u0124\n\u0003\u0002\u0002",
    "\u0002\u0125\u0126\u0007C\u0002\u0002\u0126\u0127\u0007P\u0002\u0002",
    "\u0127\u0128\u0007F\u0002\u0002\u0128\f\u0003\u0002\u0002\u0002\u0129",
    "\u012a\u0007C\u0002\u0002\u012a\u012b\u0007U\u0002\u0002\u012b\u000e",
    "\u0003\u0002\u0002\u0002\u012c\u012d\u0007C\u0002\u0002\u012d\u012e",
    "\u0007U\u0002\u0002\u012e\u012f\u0007E\u0002\u0002\u012f\u0010\u0003",
    "\u0002\u0002\u0002\u0130\u0131\u0007D\u0002\u0002\u0131\u0132\u0007",
    "G\u0002\u0002\u0132\u0133\u0007V\u0002\u0002\u0133\u0134\u0007Y\u0002",
    "\u0002\u0134\u0135\u0007G\u0002\u0002\u0135\u0136\u0007G\u0002\u0002",
    "\u0136\u0137\u0007P\u0002\u0002\u0137\u0012\u0003\u0002\u0002\u0002",
    "\u0138\u0139\u0007D\u0002\u0002\u0139\u013a\u0007[\u0002\u0002\u013a",
    "\u0014\u0003\u0002\u0002\u0002\u013b\u013c\u0007F\u0002\u0002\u013c",
    "\u013d\u0007G\u0002\u0002\u013d\u013e\u0007H\u0002\u0002\u013e\u013f",
    "\u0007C\u0002\u0002\u013f\u0140\u0007W\u0002\u0002\u0140\u0141\u0007",
    "N\u0002\u0002\u0141\u0142\u0007V\u0002\u0002\u0142\u0016\u0003\u0002",
    "\u0002\u0002\u0143\u0144\u0007F\u0002\u0002\u0144\u0145\u0007G\u0002",
    "\u0002\u0145\u0146\u0007N\u0002\u0002\u0146\u0147\u0007G\u0002\u0002",
    "\u0147\u0148\u0007V\u0002\u0002\u0148\u0149\u0007G\u0002\u0002\u0149",
    "\u0018\u0003\u0002\u0002\u0002\u014a\u014b\u0007F\u0002\u0002\u014b",
    "\u014c\u0007G\u0002\u0002\u014c\u014d\u0007U\u0002\u0002\u014d\u014e",
    "\u0007E\u0002\u0002\u014e\u001a\u0003\u0002\u0002\u0002\u014f\u0150",
    "\u0007H\u0002\u0002\u0150\u0151\u0007C\u0002\u0002\u0151\u0152\u0007",
    "N\u0002\u0002\u0152\u0153\u0007U\u0002\u0002\u0153\u0154\u0007G\u0002",
    "\u0002\u0154\u001c\u0003\u0002\u0002\u0002\u0155\u0156\u0007H\u0002",
    "\u0002\u0156\u0157\u0007T\u0002\u0002\u0157\u0158\u0007Q\u0002\u0002",
    "\u0158\u0159\u0007O\u0002\u0002\u0159\u001e\u0003\u0002\u0002\u0002",
    "\u015a\u015b\u0007K\u0002\u0002\u015b\u015c\u0007P\u0002\u0002\u015c",
    " \u0003\u0002\u0002\u0002\u015d\u015e\u0007K\u0002\u0002\u015e\u015f",
    "\u0007P\u0002\u0002\u015f\u0160\u0007U\u0002\u0002\u0160\u0161\u0007",
    "G\u0002\u0002\u0161\u0162\u0007T\u0002\u0002\u0162\u0163\u0007V\u0002",
    "\u0002\u0163\"\u0003\u0002\u0002\u0002\u0164\u0165\u0007K\u0002\u0002",
    "\u0165\u0166\u0007P\u0002\u0002\u0166\u0167\u0007V\u0002\u0002\u0167",
    "\u0168\u0007Q\u0002\u0002\u0168$\u0003\u0002\u0002\u0002\u0169\u016a",
    "\u0007K\u0002\u0002\u016a\u016b\u0007U\u0002\u0002\u016b&\u0003\u0002",
    "\u0002\u0002\u016c\u016d\u0007M\u0002\u0002\u016d\u016e\u0007G\u0002",
    "\u0002\u016e\u016f\u0007[\u0002\u0002\u016f(\u0003\u0002\u0002\u0002",
    "\u0170\u0171\u0007N\u0002\u0002\u0171\u0172\u0007K\u0002\u0002\u0172",
    "\u0173\u0007M\u0002\u0002\u0173\u0174\u0007G\u0002\u0002\u0174*\u0003",
    "\u0002\u0002\u0002\u0175\u0176\u0007N\u0002\u0002\u0176\u0177\u0007",
    "K\u0002\u0002\u0177\u0178\u0007O\u0002\u0002\u0178\u0179\u0007K\u0002",
    "\u0002\u0179\u017a\u0007V\u0002\u0002\u017a,\u0003\u0002\u0002\u0002",
    "\u017b\u017c\u0007P\u0002\u0002\u017c\u017d\u0007Q\u0002\u0002\u017d",
    "\u017e\u0007V\u0002\u0002\u017e.\u0003\u0002\u0002\u0002\u017f\u0180",
    "\u0007P\u0002\u0002\u0180\u0181\u0007W\u0002\u0002\u0181\u0182\u0007",
    "N\u0002\u0002\u0182\u0183\u0007N\u0002\u0002\u01830\u0003\u0002\u0002",
    "\u0002\u0184\u0185\u0007Q\u0002\u0002\u0185\u0186\u0007H\u0002\u0002",
    "\u0186\u0187\u0007H\u0002\u0002\u0187\u0188\u0007U\u0002\u0002\u0188",
    "\u0189\u0007G\u0002\u0002\u0189\u018a\u0007V\u0002\u0002\u018a2\u0003",
    "\u0002\u0002\u0002\u018b\u018c\u0007Q\u0002\u0002\u018c\u018d\u0007",
    "T\u0002\u0002\u018d4\u0003\u0002\u0002\u0002\u018e\u018f\u0007Q\u0002",
    "\u0002\u018f\u0190\u0007T\u0002\u0002\u0190\u0191\u0007F\u0002\u0002",
    "\u0191\u0192\u0007G\u0002\u0002\u0192\u0193\u0007T\u0002\u0002\u0193",
    "6\u0003\u0002\u0002\u0002\u0194\u0195\u0007T\u0002\u0002\u0195\u0196",
    "\u0007G\u0002\u0002\u0196\u0197\u0007I\u0002\u0002\u0197\u0198\u0007",
    "G\u0002\u0002\u0198\u0199\u0007Z\u0002\u0002\u0199\u019a\u0007R\u0002",
    "\u0002\u019a8\u0003\u0002\u0002\u0002\u019b\u019c\u0007T\u0002\u0002",
    "\u019c\u019d\u0007N\u0002\u0002\u019d\u019e\u0007K\u0002\u0002\u019e",
    "\u019f\u0007M\u0002\u0002\u019f\u01a0\u0007G\u0002\u0002\u01a0:\u0003",
    "\u0002\u0002\u0002\u01a1\u01a2\u0007U\u0002\u0002\u01a2\u01a3\u0007",
    "G\u0002\u0002\u01a3\u01a4\u0007N\u0002\u0002\u01a4\u01a5\u0007G\u0002",
    "\u0002\u01a5\u01a6\u0007E\u0002\u0002\u01a6\u01a7\u0007V\u0002\u0002",
    "\u01a7<\u0003\u0002\u0002\u0002\u01a8\u01a9\u0007U\u0002\u0002\u01a9",
    "\u01aa\u0007G\u0002\u0002\u01aa\u01ab\u0007V\u0002\u0002\u01ab>\u0003",
    "\u0002\u0002\u0002\u01ac\u01ad\u0007V\u0002\u0002\u01ad\u01ae\u0007",
    "T\u0002\u0002\u01ae\u01af\u0007W\u0002\u0002\u01af\u01b0\u0007G\u0002",
    "\u0002\u01b0@\u0003\u0002\u0002\u0002\u01b1\u01b2\u0007W\u0002\u0002",
    "\u01b2\u01b3\u0007R\u0002\u0002\u01b3\u01b4\u0007F\u0002\u0002\u01b4",
    "\u01b5\u0007C\u0002\u0002\u01b5\u01b6\u0007V\u0002\u0002\u01b6\u01b7",
    "\u0007G\u0002\u0002\u01b7B\u0003\u0002\u0002\u0002\u01b8\u01b9\u0007",
    "X\u0002\u0002\u01b9\u01ba\u0007C\u0002\u0002\u01ba\u01bb\u0007N\u0002",
    "\u0002\u01bb\u01bc\u0007W\u0002\u0002\u01bc\u01bd\u0007G\u0002\u0002",
    "\u01bdD\u0003\u0002\u0002\u0002\u01be\u01bf\u0007X\u0002\u0002\u01bf",
    "\u01c0\u0007C\u0002\u0002\u01c0\u01c1\u0007N\u0002\u0002\u01c1\u01c2",
    "\u0007W\u0002\u0002\u01c2\u01c3\u0007G\u0002\u0002\u01c3\u01c4\u0007",
    "U\u0002\u0002\u01c4F\u0003\u0002\u0002\u0002\u01c5\u01c6\u0007Y\u0002",
    "\u0002\u01c6\u01c7\u0007J\u0002\u0002\u01c7\u01c8\u0007G\u0002\u0002",
    "\u01c8\u01c9\u0007T\u0002\u0002\u01c9\u01ca\u0007G\u0002\u0002\u01ca",
    "H\u0003\u0002\u0002\u0002\u01cb\u01cc\u0007Y\u0002\u0002\u01cc\u01cd",
    "\u0007K\u0002\u0002\u01cd\u01ce\u0007V\u0002\u0002\u01ce\u01cf\u0007",
    "J\u0002\u0002\u01cfJ\u0003\u0002\u0002\u0002\u01d0\u01d1\u0007K\u0002",
    "\u0002\u01d1\u01d2\u0007P\u0002\u0002\u01d2\u01d3\u0007V\u0002\u0002",
    "\u01d3L\u0003\u0002\u0002\u0002\u01d4\u01d5\u0007K\u0002\u0002\u01d5",
    "\u01d6\u0007P\u0002\u0002\u01d6\u01d7\u0007V\u0002\u0002\u01d7\u01d8",
    "\u0007G\u0002\u0002\u01d8\u01d9\u0007I\u0002\u0002\u01d9\u01da\u0007",
    "G\u0002\u0002\u01da\u01db\u0007T\u0002\u0002\u01dbN\u0003\u0002\u0002",
    "\u0002\u01dc\u01dd\u0007T\u0002\u0002\u01dd\u01de\u0007G\u0002\u0002",
    "\u01de\u01df\u0007C\u0002\u0002\u01df\u01e0\u0007N\u0002\u0002\u01e0",
    "P\u0003\u0002\u0002\u0002\u01e1\u01e2\u0007F\u0002\u0002\u01e2\u01e3",
    "\u0007Q\u0002\u0002\u01e3\u01e4\u0007W\u0002\u0002\u01e4\u01e5\u0007",
    "D\u0002\u0002\u01e5\u01e6\u0007N\u0002\u0002\u01e6\u01e7\u0007G\u0002",
    "\u0002\u01e7R\u0003\u0002\u0002\u0002\u01e8\u01e9\u0007H\u0002\u0002",
    "\u01e9\u01ea\u0007N\u0002\u0002\u01ea\u01eb\u0007Q\u0002\u0002\u01eb",
    "\u01ec\u0007C\u0002\u0002\u01ec\u01ed\u0007V\u0002\u0002\u01edT\u0003",
    "\u0002\u0002\u0002\u01ee\u01ef\u0007F\u0002\u0002\u01ef\u01f0\u0007",
    "G\u0002\u0002\u01f0\u01f1\u0007E\u0002\u0002\u01f1\u01f2\u0007K\u0002",
    "\u0002\u01f2\u01f3\u0007O\u0002\u0002\u01f3\u01f4\u0007C\u0002\u0002",
    "\u01f4\u01f5\u0007N\u0002\u0002\u01f5V\u0003\u0002\u0002\u0002\u01f6",
    "\u01f7\u0007P\u0002\u0002\u01f7\u01f8\u0007W\u0002\u0002\u01f8\u01f9",
    "\u0007O\u0002\u0002\u01f9\u01fa\u0007G\u0002\u0002\u01fa\u01fb\u0007",
    "T\u0002\u0002\u01fb\u01fc\u0007K\u0002\u0002\u01fc\u01fd\u0007E\u0002",
    "\u0002\u01fdX\u0003\u0002\u0002\u0002\u01fe\u01ff\u0007F\u0002\u0002",
    "\u01ff\u0200\u0007C\u0002\u0002\u0200\u0201\u0007V\u0002\u0002\u0201",
    "\u0202\u0007G\u0002\u0002\u0202Z\u0003\u0002\u0002\u0002\u0203\u0204",
    "\u0007V\u0002\u0002\u0204\u0205\u0007K\u0002\u0002\u0205\u0206\u0007",
    "O\u0002\u0002\u0206\u0207\u0007G\u0002\u0002\u0207\u0208\u0007U\u0002",
    "\u0002\u0208\u0209\u0007V\u0002\u0002\u0209\u020a\u0007C\u0002\u0002",
    "\u020a\u020b\u0007O\u0002\u0002\u020b\u020c\u0007R\u0002\u0002\u020c",
    "\\\u0003\u0002\u0002\u0002\u020d\u020e\u0007F\u0002\u0002\u020e\u020f",
    "\u0007C\u0002\u0002\u020f\u0210\u0007V\u0002\u0002\u0210\u0211\u0007",
    "G\u0002\u0002\u0211\u0212\u0007V\u0002\u0002\u0212\u0213\u0007K\u0002",
    "\u0002\u0213\u0214\u0007O\u0002\u0002\u0214\u0215\u0007G\u0002\u0002",
    "\u0215^\u0003\u0002\u0002\u0002\u0216\u0217\u0007E\u0002\u0002\u0217",
    "\u0218\u0007J\u0002\u0002\u0218\u0219\u0007C\u0002\u0002\u0219\u021a",
    "\u0007T\u0002\u0002\u021a`\u0003\u0002\u0002\u0002\u021b\u021c\u0007",
    "P\u0002\u0002\u021c\u021d\u0007E\u0002\u0002\u021d\u021e\u0007J\u0002",
    "\u0002\u021e\u021f\u0007C\u0002\u0002\u021f\u0220\u0007T\u0002\u0002",
    "\u0220b\u0003\u0002\u0002\u0002\u0221\u0222\u0007X\u0002\u0002\u0222",
    "\u0223\u0007C\u0002\u0002\u0223\u0224\u0007T\u0002\u0002\u0224\u0225",
    "\u0007E\u0002\u0002\u0225\u0226\u0007J\u0002\u0002\u0226\u0227\u0007",
    "C\u0002\u0002\u0227\u0228\u0007T\u0002\u0002\u0228d\u0003\u0002\u0002",
    "\u0002\u0229\u022a\u0007P\u0002\u0002\u022a\u022b\u0007X\u0002\u0002",
    "\u022b\u022c\u0007C\u0002\u0002\u022c\u022d\u0007T\u0002\u0002\u022d",
    "\u022e\u0007E\u0002\u0002\u022e\u022f\u0007J\u0002\u0002\u022f\u0230",
    "\u0007C\u0002\u0002\u0230\u0231\u0007T\u0002\u0002\u0231f\u0003\u0002",
    "\u0002\u0002\u0232\u0233\u0007V\u0002\u0002\u0233\u0234\u0007G\u0002",
    "\u0002\u0234\u0235\u0007Z\u0002\u0002\u0235\u0236\u0007V\u0002\u0002",
    "\u0236h\u0003\u0002\u0002\u0002\u0237\u0238\u0007D\u0002\u0002\u0238",
    "\u0239\u0007Q\u0002\u0002\u0239\u023a\u0007Q\u0002\u0002\u023a\u023b",
    "\u0007N\u0002\u0002\u023bj\u0003\u0002\u0002\u0002\u023c\u023d\u0007",
    "D\u0002\u0002\u023d\u023e\u0007Q\u0002\u0002\u023e\u023f\u0007Q\u0002",
    "\u0002\u023f\u0240\u0007N\u0002\u0002\u0240\u0241\u0007G\u0002\u0002",
    "\u0241\u0242\u0007C\u0002\u0002\u0242\u0243\u0007P\u0002\u0002\u0243",
    "l\u0003\u0002\u0002\u0002\u0244\u0245\u0007E\u0002\u0002\u0245\u0246",
    "\u0007C\u0002\u0002\u0246\u0247\u0007U\u0002\u0002\u0247\u0248\u0007",
    "V\u0002\u0002\u0248n\u0003\u0002\u0002\u0002\u0249\u024a\u0007K\u0002",
    "\u0002\u024a\u024b\u0007H\u0002\u0002\u024b\u024c\u0007P\u0002\u0002",
    "\u024c\u024d\u0007W\u0002\u0002\u024d\u024e\u0007N\u0002\u0002\u024e",
    "\u024f\u0007N\u0002\u0002\u024fp\u0003\u0002\u0002\u0002\u0250\u0251",
    "\u0007P\u0002\u0002\u0251\u0252\u0007Q\u0002\u0002\u0252\u0253\u0007",
    "Y\u0002\u0002\u0253r\u0003\u0002\u0002\u0002\u0254\u0255\u0007W\u0002",
    "\u0002\u0255\u0256\u0007P\u0002\u0002\u0256\u0257\u0007F\u0002\u0002",
    "\u0257\u0258\u0007G\u0002\u0002\u0258\u0259\u0007H\u0002\u0002\u0259",
    "\u025a\u0007K\u0002\u0002\u025a\u025b\u0007P\u0002\u0002\u025b\u025c",
    "\u0007G\u0002\u0002\u025c\u025d\u0007F\u0002\u0002\u025dt\u0003\u0002",
    "\u0002\u0002\u025e\u025f\u0007,\u0002\u0002\u025fv\u0003\u0002\u0002",
    "\u0002\u0260\u0261\u00071\u0002\u0002\u0261x\u0003\u0002\u0002\u0002",
    "\u0262\u0263\u0007\'\u0002\u0002\u0263z\u0003\u0002\u0002\u0002\u0264",
    "\u0265\u0007-\u0002\u0002\u0265|\u0003\u0002\u0002\u0002\u0266\u0267",
    "\u0007/\u0002\u0002\u0267\u0268\u0007/\u0002\u0002\u0268~\u0003\u0002",
    "\u0002\u0002\u0269\u026a\u0007/\u0002\u0002\u026a\u0080\u0003\u0002",
    "\u0002\u0002\u026b\u026c\u0007?\u0002\u0002\u026c\u0082\u0003\u0002",
    "\u0002\u0002\u026d\u026e\u0007@\u0002\u0002\u026e\u0084\u0003\u0002",
    "\u0002\u0002\u026f\u0270\u0007>\u0002\u0002\u0270\u0086\u0003\u0002",
    "\u0002\u0002\u0271\u0272\u0007#\u0002\u0002\u0272\u0088\u0003\u0002",
    "\u0002\u0002\u0273\u0274\u0007~\u0002\u0002\u0274\u008a\u0003\u0002",
    "\u0002\u0002\u0275\u0276\u0007(\u0002\u0002\u0276\u008c\u0003\u0002",
    "\u0002\u0002\u0277\u0278\u00070\u0002\u0002\u0278\u008e\u0003\u0002",
    "\u0002\u0002\u0279\u027a\u0007*\u0002\u0002\u027a\u0090\u0003\u0002",
    "\u0002\u0002\u027b\u027c\u0007+\u0002\u0002\u027c\u0092\u0003\u0002",
    "\u0002\u0002\u027d\u027e\u0007.\u0002\u0002\u027e\u0094\u0003\u0002",
    "\u0002\u0002\u027f\u0280\u0007=\u0002\u0002\u0280\u0096\u0003\u0002",
    "\u0002\u0002\u0281\u0282\u00072\u0002\u0002\u0282\u0098\u0003\u0002",
    "\u0002\u0002\u0283\u0284\u00073\u0002\u0002\u0284\u009a\u0003\u0002",
    "\u0002\u0002\u0285\u0286\u00074\u0002\u0002\u0286\u009c\u0003\u0002",
    "\u0002\u0002\u0287\u0288\u0007)\u0002\u0002\u0288\u009e\u0003\u0002",
    "\u0002\u0002\u0289\u028a\u0007$\u0002\u0002\u028a\u00a0\u0003\u0002",
    "\u0002\u0002\u028b\u028c\u0007b\u0002\u0002\u028c\u00a2\u0003\u0002",
    "\u0002\u0002\u028d\u028e\u0007<\u0002\u0002\u028e\u00a4\u0003\u0002",
    "\u0002\u0002\u028f\u0293\u0005\u009dO\u0002\u0290\u0293\u0005\u009f",
    "P\u0002\u0291\u0293\u0005\u00a1Q\u0002\u0292\u028f\u0003\u0002\u0002",
    "\u0002\u0292\u0290\u0003\u0002\u0002\u0002\u0292\u0291\u0003\u0002\u0002",
    "\u0002\u0293\u00a6\u0003\u0002\u0002\u0002\u0294\u0296\u0005\u00d9m",
    "\u0002\u0295\u0294\u0003\u0002\u0002\u0002\u0296\u0297\u0003\u0002\u0002",
    "\u0002\u0297\u0295\u0003\u0002\u0002\u0002\u0297\u0298\u0003\u0002\u0002",
    "\u0002\u0298\u0299\u0003\u0002\u0002\u0002\u0299\u029a\t\u0004\u0002",
    "\u0002\u029a\u00a8\u0003\u0002\u0002\u0002\u029b\u029c\u0007P\u0002",
    "\u0002\u029c\u029d\u0005\u00d3j\u0002\u029d\u00aa\u0003\u0002\u0002",
    "\u0002\u029e\u029f\u0005\u00d3j\u0002\u029f\u00ac\u0003\u0002\u0002",
    "\u0002\u02a0\u02a2\u0005\u00d9m\u0002\u02a1\u02a0\u0003\u0002\u0002",
    "\u0002\u02a2\u02a3\u0003\u0002\u0002\u0002\u02a3\u02a1\u0003\u0002\u0002",
    "\u0002\u02a3\u02a4\u0003\u0002\u0002\u0002\u02a4\u00ae\u0003\u0002\u0002",
    "\u0002\u02a5\u02a6\u0007Z\u0002\u0002\u02a6\u02aa\u0007)\u0002\u0002",
    "\u02a7\u02a8\u0005\u00d7l\u0002\u02a8\u02a9\u0005\u00d7l\u0002\u02a9",
    "\u02ab\u0003\u0002\u0002\u0002\u02aa\u02a7\u0003\u0002\u0002\u0002\u02ab",
    "\u02ac\u0003\u0002\u0002\u0002\u02ac\u02aa\u0003\u0002\u0002\u0002\u02ac",
    "\u02ad\u0003\u0002\u0002\u0002\u02ad\u02ae\u0003\u0002\u0002\u0002\u02ae",
    "\u02af\u0007)\u0002\u0002\u02af\u02b9\u0003\u0002\u0002\u0002\u02b0",
    "\u02b1\u00072\u0002\u0002\u02b1\u02b2\u0007Z\u0002\u0002\u02b2\u02b4",
    "\u0003\u0002\u0002\u0002\u02b3\u02b5\u0005\u00d7l\u0002\u02b4\u02b3",
    "\u0003\u0002\u0002\u0002\u02b5\u02b6\u0003\u0002\u0002\u0002\u02b6\u02b4",
    "\u0003\u0002\u0002\u0002\u02b6\u02b7\u0003\u0002\u0002\u0002\u02b7\u02b9",
    "\u0003\u0002\u0002\u0002\u02b8\u02a5\u0003\u0002\u0002\u0002\u02b8\u02b0",
    "\u0003\u0002\u0002\u0002\u02b9\u00b0\u0003\u0002\u0002\u0002\u02ba\u02bc",
    "\u0005\u00d9m\u0002\u02bb\u02ba\u0003\u0002\u0002\u0002\u02bc\u02bd",
    "\u0003\u0002\u0002\u0002\u02bd\u02bb\u0003\u0002\u0002\u0002\u02bd\u02be",
    "\u0003\u0002\u0002\u0002\u02be\u02c0\u0003\u0002\u0002\u0002\u02bf\u02bb",
    "\u0003\u0002\u0002\u0002\u02bf\u02c0\u0003\u0002\u0002\u0002\u02c0\u02c1",
    "\u0003\u0002\u0002\u0002\u02c1\u02c3\u00070\u0002\u0002\u02c2\u02c4",
    "\u0005\u00d9m\u0002\u02c3\u02c2\u0003\u0002\u0002\u0002\u02c4\u02c5",
    "\u0003\u0002\u0002\u0002\u02c5\u02c3\u0003\u0002\u0002\u0002\u02c5\u02c6",
    "\u0003\u0002\u0002\u0002\u02c6\u02e6\u0003\u0002\u0002\u0002\u02c7\u02c9",
    "\u0005\u00d9m\u0002\u02c8\u02c7\u0003\u0002\u0002\u0002\u02c9\u02ca",
    "\u0003\u0002\u0002\u0002\u02ca\u02c8\u0003\u0002\u0002\u0002\u02ca\u02cb",
    "\u0003\u0002\u0002\u0002\u02cb\u02cc\u0003\u0002\u0002\u0002\u02cc\u02cd",
    "\u00070\u0002\u0002\u02cd\u02ce\u0005\u00cdg\u0002\u02ce\u02e6\u0003",
    "\u0002\u0002\u0002\u02cf\u02d1\u0005\u00d9m\u0002\u02d0\u02cf\u0003",
    "\u0002\u0002\u0002\u02d1\u02d2\u0003\u0002\u0002\u0002\u02d2\u02d0\u0003",
    "\u0002\u0002\u0002\u02d2\u02d3\u0003\u0002\u0002\u0002\u02d3\u02d5\u0003",
    "\u0002\u0002\u0002\u02d4\u02d0\u0003\u0002\u0002\u0002\u02d4\u02d5\u0003",
    "\u0002\u0002\u0002\u02d5\u02d6\u0003\u0002\u0002\u0002\u02d6\u02d8\u0007",
    "0\u0002\u0002\u02d7\u02d9\u0005\u00d9m\u0002\u02d8\u02d7\u0003\u0002",
    "\u0002\u0002\u02d9\u02da\u0003\u0002\u0002\u0002\u02da\u02d8\u0003\u0002",
    "\u0002\u0002\u02da\u02db\u0003\u0002\u0002\u0002\u02db\u02dc\u0003\u0002",
    "\u0002\u0002\u02dc\u02dd\u0005\u00cdg\u0002\u02dd\u02e6\u0003\u0002",
    "\u0002\u0002\u02de\u02e0\u0005\u00d9m\u0002\u02df\u02de\u0003\u0002",
    "\u0002\u0002\u02e0\u02e1\u0003\u0002\u0002\u0002\u02e1\u02df\u0003\u0002",
    "\u0002\u0002\u02e1\u02e2\u0003\u0002\u0002\u0002\u02e2\u02e3\u0003\u0002",
    "\u0002\u0002\u02e3\u02e4\u0005\u00cdg\u0002\u02e4\u02e6\u0003\u0002",
    "\u0002\u0002\u02e5\u02bf\u0003\u0002\u0002\u0002\u02e5\u02c8\u0003\u0002",
    "\u0002\u0002\u02e5\u02d4\u0003\u0002\u0002\u0002\u02e5\u02df\u0003\u0002",
    "\u0002\u0002\u02e6\u00b2\u0003\u0002\u0002\u0002\u02e7\u02e8\u0007^",
    "\u0002\u0002\u02e8\u02e9\u0007P\u0002\u0002\u02e9\u00b4\u0003\u0002",
    "\u0002\u0002\u02ea\u02eb\u0005\u00dbn\u0002\u02eb\u00b6\u0003\u0002",
    "\u0002\u0002\u02ec\u02ed\u0007]\u0002\u0002\u02ed\u00b8\u0003\u0002",
    "\u0002\u0002\u02ee\u02ef\u0007_\u0002\u0002\u02ef\u00ba\u0003\u0002",
    "\u0002\u0002\u02f0\u02f1\u0007}\u0002\u0002\u02f1\u00bc\u0003\u0002",
    "\u0002\u0002\u02f2\u02f3\u0007\u007f\u0002\u0002\u02f3\u00be\u0003\u0002",
    "\u0002\u0002\u02f4\u02f5\u00070\u0002\u0002\u02f5\u02f6\u0005\u00cf",
    "h\u0002\u02f6\u00c0\u0003\u0002\u0002\u0002\u02f7\u02f8\u0005\u00cf",
    "h\u0002\u02f8\u00c2\u0003\u0002\u0002\u0002\u02f9\u02fa\u0005\u00d1",
    "i\u0002\u02fa\u00c4\u0003\u0002\u0002\u0002\u02fb\u02fc\u0005\u00d5",
    "k\u0002\u02fc\u00c6\u0003\u0002\u0002\u0002\u02fd\u0302\u0005\u00d3",
    "j\u0002\u02fe\u0302\u0005\u00d1i\u0002\u02ff\u0302\u0005\u00d5k\u0002",
    "\u0300\u0302\u0005\u00cfh\u0002\u0301\u02fd\u0003\u0002\u0002\u0002",
    "\u0301\u02fe\u0003\u0002\u0002\u0002\u0301\u02ff\u0003\u0002\u0002\u0002",
    "\u0301\u0300\u0003\u0002\u0002\u0002\u0302\u0303\u0003\u0002\u0002\u0002",
    "\u0303\u0308\u0007B\u0002\u0002\u0304\u0309\u0005\u00d3j\u0002\u0305",
    "\u0309\u0005\u00d1i\u0002\u0306\u0309\u0005\u00d5k\u0002\u0307\u0309",
    "\u0005\u00cfh\u0002\u0308\u0304\u0003\u0002\u0002\u0002\u0308\u0305",
    "\u0003\u0002\u0002\u0002\u0308\u0306\u0003\u0002\u0002\u0002\u0308\u0307",
    "\u0003\u0002\u0002\u0002\u0309\u00c8\u0003\u0002\u0002\u0002\u030a\u0313",
    "\u0007B\u0002\u0002\u030b\u030d\t\u0005\u0002\u0002\u030c\u030b\u0003",
    "\u0002\u0002\u0002\u030d\u030e\u0003\u0002\u0002\u0002\u030e\u030c\u0003",
    "\u0002\u0002\u0002\u030e\u030f\u0003\u0002\u0002\u0002\u030f\u0314\u0003",
    "\u0002\u0002\u0002\u0310\u0314\u0005\u00d3j\u0002\u0311\u0314\u0005",
    "\u00d1i\u0002\u0312\u0314\u0005\u00d5k\u0002\u0313\u030c\u0003\u0002",
    "\u0002\u0002\u0313\u0310\u0003\u0002\u0002\u0002\u0313\u0311\u0003\u0002",
    "\u0002\u0002\u0313\u0312\u0003\u0002\u0002\u0002\u0314\u00ca\u0003\u0002",
    "\u0002\u0002\u0315\u0316\u0007B\u0002\u0002\u0316\u031d\u0007B\u0002",
    "\u0002\u0317\u0319\t\u0005\u0002\u0002\u0318\u0317\u0003\u0002\u0002",
    "\u0002\u0319\u031a\u0003\u0002\u0002\u0002\u031a\u0318\u0003\u0002\u0002",
    "\u0002\u031a\u031b\u0003\u0002\u0002\u0002\u031b\u031e\u0003\u0002\u0002",
    "\u0002\u031c\u031e\u0005\u00d5k\u0002\u031d\u0318\u0003\u0002\u0002",
    "\u0002\u031d\u031c\u0003\u0002\u0002\u0002\u031e\u00cc\u0003\u0002\u0002",
    "\u0002\u031f\u0321\u0007G\u0002\u0002\u0320\u0322\t\u0006\u0002\u0002",
    "\u0321\u0320\u0003\u0002\u0002\u0002\u0321\u0322\u0003\u0002\u0002\u0002",
    "\u0322\u0324\u0003\u0002\u0002\u0002\u0323\u0325\u0005\u00d9m\u0002",
    "\u0324\u0323\u0003\u0002\u0002\u0002\u0325\u0326\u0003\u0002\u0002\u0002",
    "\u0326\u0324\u0003\u0002\u0002\u0002\u0326\u0327\u0003\u0002\u0002\u0002",
    "\u0327\u00ce\u0003\u0002\u0002\u0002\u0328\u032a\t\u0007\u0002\u0002",
    "\u0329\u0328\u0003\u0002\u0002\u0002\u032a\u032b\u0003\u0002\u0002\u0002",
    "\u032b\u032c\u0003\u0002\u0002\u0002\u032b\u0329\u0003\u0002\u0002\u0002",
    "\u032c\u0330\u0003\u0002\u0002\u0002\u032d\u032f\t\b\u0002\u0002\u032e",
    "\u032d\u0003\u0002\u0002\u0002\u032f\u0332\u0003\u0002\u0002\u0002\u0330",
    "\u032e\u0003\u0002\u0002\u0002\u0330\u0331\u0003\u0002\u0002\u0002\u0331",
    "\u00d0\u0003\u0002\u0002\u0002\u0332\u0330\u0003\u0002\u0002\u0002\u0333",
    "\u033b\u0007$\u0002\u0002\u0334\u0335\u0007^\u0002\u0002\u0335\u033a",
    "\u000b\u0002\u0002\u0002\u0336\u0337\u0007$\u0002\u0002\u0337\u033a",
    "\u0007$\u0002\u0002\u0338\u033a\n\t\u0002\u0002\u0339\u0334\u0003\u0002",
    "\u0002\u0002\u0339\u0336\u0003\u0002\u0002\u0002\u0339\u0338\u0003\u0002",
    "\u0002\u0002\u033a\u033d\u0003\u0002\u0002\u0002\u033b\u0339\u0003\u0002",
    "\u0002\u0002\u033b\u033c\u0003\u0002\u0002\u0002\u033c\u033e\u0003\u0002",
    "\u0002\u0002\u033d\u033b\u0003\u0002\u0002\u0002\u033e\u033f\u0007$",
    "\u0002\u0002\u033f\u00d2\u0003\u0002\u0002\u0002\u0340\u0348\u0007)",
    "\u0002\u0002\u0341\u0342\u0007^\u0002\u0002\u0342\u0347\u000b\u0002",
    "\u0002\u0002\u0343\u0344\u0007)\u0002\u0002\u0344\u0347\u0007)\u0002",
    "\u0002\u0345\u0347\n\n\u0002\u0002\u0346\u0341\u0003\u0002\u0002\u0002",
    "\u0346\u0343\u0003\u0002\u0002\u0002\u0346\u0345\u0003\u0002\u0002\u0002",
    "\u0347\u034a\u0003\u0002\u0002\u0002\u0348\u0346\u0003\u0002\u0002\u0002",
    "\u0348\u0349\u0003\u0002\u0002\u0002\u0349\u034b\u0003\u0002\u0002\u0002",
    "\u034a\u0348\u0003\u0002\u0002\u0002\u034b\u034c\u0007)\u0002\u0002",
    "\u034c\u00d4\u0003\u0002\u0002\u0002\u034d\u0355\u0007b\u0002\u0002",
    "\u034e\u034f\u0007^\u0002\u0002\u034f\u0354\u000b\u0002\u0002\u0002",
    "\u0350\u0351\u0007b\u0002\u0002\u0351\u0354\u0007b\u0002\u0002\u0352",
    "\u0354\n\u000b\u0002\u0002\u0353\u034e\u0003\u0002\u0002\u0002\u0353",
    "\u0350\u0003\u0002\u0002\u0002\u0353\u0352\u0003\u0002\u0002\u0002\u0354",
    "\u0357\u0003\u0002\u0002\u0002\u0355\u0353\u0003\u0002\u0002\u0002\u0355",
    "\u0356\u0003\u0002\u0002\u0002\u0356\u0358\u0003\u0002\u0002\u0002\u0357",
    "\u0355\u0003\u0002\u0002\u0002\u0358\u0359\u0007b\u0002\u0002\u0359",
    "\u00d6\u0003\u0002\u0002\u0002\u035a\u035b\t\f\u0002\u0002\u035b\u00d8",
    "\u0003\u0002\u0002\u0002\u035c\u035d\t\r\u0002\u0002\u035d\u00da\u0003",
    "\u0002\u0002\u0002\u035e\u035f\u0007D\u0002\u0002\u035f\u0361\u0007",
    ")\u0002\u0002\u0360\u0362\t\u000e\u0002\u0002\u0361\u0360\u0003\u0002",
    "\u0002\u0002\u0362\u0363\u0003\u0002\u0002\u0002\u0363\u0361\u0003\u0002",
    "\u0002\u0002\u0363\u0364\u0003\u0002\u0002\u0002\u0364\u0365\u0003\u0002",
    "\u0002\u0002\u0365\u0366\u0007)\u0002\u0002\u0366\u00dc\u0003\u0002",
    "\u0002\u0002\u0367\u0368\u000b\u0002\u0002\u0002\u0368\u0369\u0003\u0002",
    "\u0002\u0002\u0369\u036a\bo\u0004\u0002\u036a\u00de\u0003\u0002\u0002",
    "\u0002-\u0002\u00e2\u00ed\u00fa\u0108\u010d\u0111\u0115\u011b\u011f",
    "\u0121\u0292\u0297\u02a3\u02ac\u02b6\u02b8\u02bd\u02bf\u02c5\u02ca\u02d2",
    "\u02d4\u02da\u02e1\u02e5\u0301\u0308\u030e\u0313\u031a\u031d\u0321\u0326",
    "\u032b\u0330\u0339\u033b\u0346\u0348\u0353\u0355\u0363\u0005\u0002\u0003",
    "\u0002\u0002\u0004\u0002\u0002\u0005\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function SqlLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

SqlLexer.prototype = Object.create(antlr4.Lexer.prototype);
SqlLexer.prototype.constructor = SqlLexer;

Object.defineProperty(SqlLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

SqlLexer.EOF = antlr4.Token.EOF;
SqlLexer.SPACE = 1;
SqlLexer.SPEC_MYSQL_COMMENT = 2;
SqlLexer.COMMENT_INPUT = 3;
SqlLexer.LINE_COMMENT = 4;
SqlLexer.AND = 5;
SqlLexer.AS = 6;
SqlLexer.ASC = 7;
SqlLexer.BETWEEN = 8;
SqlLexer.BY = 9;
SqlLexer.DEFAULT = 10;
SqlLexer.DELETE = 11;
SqlLexer.DESC = 12;
SqlLexer.FALSE = 13;
SqlLexer.FROM = 14;
SqlLexer.IN = 15;
SqlLexer.INSERT = 16;
SqlLexer.INTO = 17;
SqlLexer.IS = 18;
SqlLexer.KEY = 19;
SqlLexer.LIKE = 20;
SqlLexer.LIMIT = 21;
SqlLexer.NOT = 22;
SqlLexer.NULL_LITERAL = 23;
SqlLexer.OFFSET = 24;
SqlLexer.OR = 25;
SqlLexer.ORDER = 26;
SqlLexer.REGEXP = 27;
SqlLexer.RLIKE = 28;
SqlLexer.SELECT = 29;
SqlLexer.SET = 30;
SqlLexer.TRUE = 31;
SqlLexer.UPDATE = 32;
SqlLexer.VALUE = 33;
SqlLexer.VALUES = 34;
SqlLexer.WHERE = 35;
SqlLexer.WITH = 36;
SqlLexer.INT = 37;
SqlLexer.INTEGER = 38;
SqlLexer.REAL = 39;
SqlLexer.DOUBLE = 40;
SqlLexer.FLOAT = 41;
SqlLexer.DECIMAL = 42;
SqlLexer.NUMERIC = 43;
SqlLexer.DATE = 44;
SqlLexer.TIMESTAMP = 45;
SqlLexer.DATETIME = 46;
SqlLexer.CHAR = 47;
SqlLexer.NCHAR = 48;
SqlLexer.VARCHAR = 49;
SqlLexer.NVARCHAR = 50;
SqlLexer.TEXT = 51;
SqlLexer.BOOL = 52;
SqlLexer.BOOLEAN = 53;
SqlLexer.CAST = 54;
SqlLexer.IFNULL = 55;
SqlLexer.NOW = 56;
SqlLexer.UNDEFINED = 57;
SqlLexer.STAR = 58;
SqlLexer.DIVIDE = 59;
SqlLexer.MODULE = 60;
SqlLexer.PLUS = 61;
SqlLexer.MINUSMINUS = 62;
SqlLexer.MINUS = 63;
SqlLexer.EQUAL_SYMBOL = 64;
SqlLexer.GREATER_SYMBOL = 65;
SqlLexer.LESS_SYMBOL = 66;
SqlLexer.EXCLAMATION_SYMBOL = 67;
SqlLexer.BIT_OR_OP = 68;
SqlLexer.BIT_AND_OP = 69;
SqlLexer.DOT = 70;
SqlLexer.LR_BRACKET = 71;
SqlLexer.RR_BRACKET = 72;
SqlLexer.COMMA = 73;
SqlLexer.SEMI = 74;
SqlLexer.ZERO_DECIMAL = 75;
SqlLexer.ONE_DECIMAL = 76;
SqlLexer.TWO_DECIMAL = 77;
SqlLexer.SINGLE_QUOTE_SYMB = 78;
SqlLexer.DOUBLE_QUOTE_SYMB = 79;
SqlLexer.REVERSE_QUOTE_SYMB = 80;
SqlLexer.COLON_SYMB = 81;
SqlLexer.FILESIZE_LITERAL = 82;
SqlLexer.START_NATIONAL_STRING_LITERAL = 83;
SqlLexer.STRING_LITERAL = 84;
SqlLexer.DECIMAL_LITERAL = 85;
SqlLexer.HEXADECIMAL_LITERAL = 86;
SqlLexer.REAL_LITERAL = 87;
SqlLexer.NULL_SPEC_LITERAL = 88;
SqlLexer.BIT_STRING = 89;
SqlLexer.BLOCK_QUOTE_OPEN = 90;
SqlLexer.BLOCK_QUOTE_CLOSE = 91;
SqlLexer.CODE_QUOTE_OPEN = 92;
SqlLexer.CODE_QUOTE_CLOSE = 93;
SqlLexer.DOT_ID = 94;
SqlLexer.ID = 95;
SqlLexer.DOUBLE_QUOTE_ID = 96;
SqlLexer.REVERSE_QUOTE_ID = 97;
SqlLexer.STRING_USER_NAME = 98;
SqlLexer.LOCAL_ID = 99;
SqlLexer.GLOBAL_ID = 100;
SqlLexer.ERROR_RECONGNIGION = 101;

SqlLexer.MYSQLCOMMENT = 2;
SqlLexer.ERRORCHANNEL = 3;

SqlLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN", "MYSQLCOMMENT", 
                                                                       "ERRORCHANNEL" ];

SqlLexer.prototype.modeNames = [ "DEFAULT_MODE" ];

SqlLexer.prototype.literalNames = [ null, null, null, null, null, "'AND'", 
                                    "'AS'", "'ASC'", "'BETWEEN'", "'BY'", 
                                    "'DEFAULT'", "'DELETE'", "'DESC'", "'FALSE'", 
                                    "'FROM'", "'IN'", "'INSERT'", "'INTO'", 
                                    "'IS'", "'KEY'", "'LIKE'", "'LIMIT'", 
                                    "'NOT'", "'NULL'", "'OFFSET'", "'OR'", 
                                    "'ORDER'", "'REGEXP'", "'RLIKE'", "'SELECT'", 
                                    "'SET'", "'TRUE'", "'UPDATE'", "'VALUE'", 
                                    "'VALUES'", "'WHERE'", "'WITH'", "'INT'", 
                                    "'INTEGER'", "'REAL'", "'DOUBLE'", "'FLOAT'", 
                                    "'DECIMAL'", "'NUMERIC'", "'DATE'", 
                                    "'TIMESTAMP'", "'DATETIME'", "'CHAR'", 
                                    "'NCHAR'", "'VARCHAR'", "'NVARCHAR'", 
                                    "'TEXT'", "'BOOL'", "'BOOLEAN'", "'CAST'", 
                                    "'IFNULL'", "'NOW'", "'UNDEFINED'", 
                                    "'*'", "'/'", "'%'", "'+'", "'--'", 
                                    "'-'", "'='", "'>'", "'<'", "'!'", "'|'", 
                                    "'&'", "'.'", "'('", "')'", "','", "';'", 
                                    "'0'", "'1'", "'2'", "'''", "'\"'", 
                                    "'`'", "':'", null, null, null, null, 
                                    null, null, null, null, "'['", "']'", 
                                    "'{'", "'}'" ];

SqlLexer.prototype.symbolicNames = [ null, "SPACE", "SPEC_MYSQL_COMMENT", 
                                     "COMMENT_INPUT", "LINE_COMMENT", "AND", 
                                     "AS", "ASC", "BETWEEN", "BY", "DEFAULT", 
                                     "DELETE", "DESC", "FALSE", "FROM", 
                                     "IN", "INSERT", "INTO", "IS", "KEY", 
                                     "LIKE", "LIMIT", "NOT", "NULL_LITERAL", 
                                     "OFFSET", "OR", "ORDER", "REGEXP", 
                                     "RLIKE", "SELECT", "SET", "TRUE", "UPDATE", 
                                     "VALUE", "VALUES", "WHERE", "WITH", 
                                     "INT", "INTEGER", "REAL", "DOUBLE", 
                                     "FLOAT", "DECIMAL", "NUMERIC", "DATE", 
                                     "TIMESTAMP", "DATETIME", "CHAR", "NCHAR", 
                                     "VARCHAR", "NVARCHAR", "TEXT", "BOOL", 
                                     "BOOLEAN", "CAST", "IFNULL", "NOW", 
                                     "UNDEFINED", "STAR", "DIVIDE", "MODULE", 
                                     "PLUS", "MINUSMINUS", "MINUS", "EQUAL_SYMBOL", 
                                     "GREATER_SYMBOL", "LESS_SYMBOL", "EXCLAMATION_SYMBOL", 
                                     "BIT_OR_OP", "BIT_AND_OP", "DOT", "LR_BRACKET", 
                                     "RR_BRACKET", "COMMA", "SEMI", "ZERO_DECIMAL", 
                                     "ONE_DECIMAL", "TWO_DECIMAL", "SINGLE_QUOTE_SYMB", 
                                     "DOUBLE_QUOTE_SYMB", "REVERSE_QUOTE_SYMB", 
                                     "COLON_SYMB", "FILESIZE_LITERAL", "START_NATIONAL_STRING_LITERAL", 
                                     "STRING_LITERAL", "DECIMAL_LITERAL", 
                                     "HEXADECIMAL_LITERAL", "REAL_LITERAL", 
                                     "NULL_SPEC_LITERAL", "BIT_STRING", 
                                     "BLOCK_QUOTE_OPEN", "BLOCK_QUOTE_CLOSE", 
                                     "CODE_QUOTE_OPEN", "CODE_QUOTE_CLOSE", 
                                     "DOT_ID", "ID", "DOUBLE_QUOTE_ID", 
                                     "REVERSE_QUOTE_ID", "STRING_USER_NAME", 
                                     "LOCAL_ID", "GLOBAL_ID", "ERROR_RECONGNIGION" ];

SqlLexer.prototype.ruleNames = [ "SPACE", "SPEC_MYSQL_COMMENT", "COMMENT_INPUT", 
                                 "LINE_COMMENT", "AND", "AS", "ASC", "BETWEEN", 
                                 "BY", "DEFAULT", "DELETE", "DESC", "FALSE", 
                                 "FROM", "IN", "INSERT", "INTO", "IS", "KEY", 
                                 "LIKE", "LIMIT", "NOT", "NULL_LITERAL", 
                                 "OFFSET", "OR", "ORDER", "REGEXP", "RLIKE", 
                                 "SELECT", "SET", "TRUE", "UPDATE", "VALUE", 
                                 "VALUES", "WHERE", "WITH", "INT", "INTEGER", 
                                 "REAL", "DOUBLE", "FLOAT", "DECIMAL", "NUMERIC", 
                                 "DATE", "TIMESTAMP", "DATETIME", "CHAR", 
                                 "NCHAR", "VARCHAR", "NVARCHAR", "TEXT", 
                                 "BOOL", "BOOLEAN", "CAST", "IFNULL", "NOW", 
                                 "UNDEFINED", "STAR", "DIVIDE", "MODULE", 
                                 "PLUS", "MINUSMINUS", "MINUS", "EQUAL_SYMBOL", 
                                 "GREATER_SYMBOL", "LESS_SYMBOL", "EXCLAMATION_SYMBOL", 
                                 "BIT_OR_OP", "BIT_AND_OP", "DOT", "LR_BRACKET", 
                                 "RR_BRACKET", "COMMA", "SEMI", "ZERO_DECIMAL", 
                                 "ONE_DECIMAL", "TWO_DECIMAL", "SINGLE_QUOTE_SYMB", 
                                 "DOUBLE_QUOTE_SYMB", "REVERSE_QUOTE_SYMB", 
                                 "COLON_SYMB", "QUOTE_SYMB", "FILESIZE_LITERAL", 
                                 "START_NATIONAL_STRING_LITERAL", "STRING_LITERAL", 
                                 "DECIMAL_LITERAL", "HEXADECIMAL_LITERAL", 
                                 "REAL_LITERAL", "NULL_SPEC_LITERAL", "BIT_STRING", 
                                 "BLOCK_QUOTE_OPEN", "BLOCK_QUOTE_CLOSE", 
                                 "CODE_QUOTE_OPEN", "CODE_QUOTE_CLOSE", 
                                 "DOT_ID", "ID", "DOUBLE_QUOTE_ID", "REVERSE_QUOTE_ID", 
                                 "STRING_USER_NAME", "LOCAL_ID", "GLOBAL_ID", 
                                 "EXPONENT_NUM_PART", "ID_LITERAL", "DQUOTA_STRING", 
                                 "SQUOTA_STRING", "BQUOTA_STRING", "HEX_DIGIT", 
                                 "DEC_DIGIT", "BIT_STRING_L", "ERROR_RECONGNIGION" ];

SqlLexer.prototype.grammarFileName = "SqlLexer.g4";


exports.SqlLexer = SqlLexer;

